import { NgModule } from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./modules/auth/guards/auth-guard";
import {ChangelogComponent} from "./components/changelog/changelog.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: { requiresAuth: true, requiredRole: 'isAdmin' }
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'calculators',
    loadChildren: () =>
      import('./modules/calculators/calculators.module').then(
        (m) => m.CalculatorsModule
      ),
    canActivate: [AuthGuard],
    data: { requiresAuth: true }
// data: { requiredRole: 'calculators' },
  },
  {
    path: 'cash-flows',
    loadChildren: () =>
      import('./modules/cash-flows/cash-flows.module').then(
        (m) => m.CashFlowsModule
      ),
    canActivate: [AuthGuard],
    data: { requiresAuth: true, requiredRole: 'trackingTools' }
  },
  {
    path: 'changelog',
    component: ChangelogComponent
  },
  {
    path: 'coaching',
    loadChildren: () =>
      import('./modules/coaching/coaching.module').then(
        (m) => m.CoachingModule
      ),
    canActivate: [AuthGuard],
    data: { requiresAuth: true }
  },
  {
    path: 'customize',
    loadChildren: () =>
      import('./modules/customization/customization.module').then(
        (m) => m.CustomizationModule
      ),
    canActivate: [AuthGuard],
    data: { requiresAuth: true }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    // resolve: { auth: AuthResolverService }
    data: { requiresAuth: true }
  },
  {
    path: 'file-management',
    loadChildren: () =>
      import('./modules/file-management/file-management.module').then(
        (m) => m.FileManagementModule
      ),
    canActivate: [AuthGuard],
    data: { requiresAuth: true, requiredRole: 'trackingTools' }
  },
  {
    path: 'financial-positions',
    loadChildren: () =>
      import('./modules/financial-positions/financial-positions.module').then(
        (m) => m.FinancialPositionsModule
      ),
    canActivate: [AuthGuard],
    data: { requiresAuth: true, requiredRole: 'trackingTools' }
  },
  {
    path: 'resources',
    loadChildren: () =>
      import('./modules/resources/resources.module').then(
        (m) => m.ResourcesModule
      ),
    canActivate: [AuthGuard],
    data: { requiresAuth: true }
  },
  {
    path: 'spending-plans',
    loadChildren: () =>
      import('./modules/spending-plans/spending-plans.module').then(
        (m) => m.SpendingPlansModule
      ),
    canActivate: [AuthGuard],
    data: { requiresAuth: true, requiredRole: 'trackingTools' }
  },
  {
    path: 'stripe',
    loadChildren: () =>
      import('./modules/stripe/stripe.module').then(
        (m) => m.StripeModule
      ),
    canActivate: [AuthGuard],
    data: { requiresAuth: true }
  },
  {
    path: '**',
    redirectTo: 'dashboard', // Redirect any unknown paths
    pathMatch: 'full',
  }
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled', // Restore scroll position
  anchorScrolling: 'enabled' // Enable fragment scrolling
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
