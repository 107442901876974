<div class="w-full h-full absolute md:left-2 top-2 rounded-xl bg-white shadow-xl ring-1 ring-gray-400/10 flex flex-col order-first items-center py-16 pt-20 pb-20">
  <div class="flex flex-col gap-3 mb-20">
    <div class="flex flex-row w-full justify-center items-center">

      <h1 class="text-2xl">{{goalType}} Goals</h1>
    </div>
<!--    <div class=" flex flex-row justify-start" (click)="sortGoal()">-->
<!--      <p class="text-sm">Sort By Date</p>-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">-->
<!--        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />-->
<!--      </svg>-->
<!--    </div>-->
    <div *ngFor="let goalControl of goalsFormArray.controls; index as i" class="relative flex justify-between w-full">
      <tandem-form-checkbox
        [disabled]="!editable"
        [control]="getControl(goalControl)"
        [controlName]="i.toString()"
        [inputId]="goals[i].id!.toString()"
        (checkboxToggled)="onToggle($event, goals[i])"
        [label]="getLabelForGoal(i)"
        [description]="getDateString(i)"
        [ngClass]="{'text-red': goalIsExpired(i), 'line-through': goals[i].completed}"
      >
      </tandem-form-checkbox>
      <div class="flex flex-col justify-start px-2 md:px-5">
<!--        <svg *ngIf="editable" (click)="onDelete(i)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">-->
<!--          <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />-->
<!--        </svg>-->
      </div>
    </div>
    <div *ngIf="goals.length === 0">
      <h4>No {{goalType}} goals</h4>
    </div>
<!--    <div class="flex flex-row justify-center">-->
<!--      <button theme="outline" color="primary" [onClick]="openGoalDialog.bind(this)"> Add {{goalType}} Goal</button>-->
<!--    </div>-->
  </div>
</div>
