import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ModalRef} from "../../../tandem-core/models/modal-ref";

@Component({
  selector: 'tandem-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent implements OnInit {

  isLoading = false;

  emailForm: FormGroup = new FormGroup<any>({});

  errors?: string;
  requestSent = false;
  public modalRef?: ModalRef;

  constructor(private auth: AuthService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    if (this.emailForm.valid) {
      this.isLoading = true;
      this.requestSent = true;
      // this.auth.sendPasswordResetEmail(this.emailForm.get('email')?.value).subscribe(res => {
      //   this.isLoading = false;
      // }, error => {
      //   this.errors = error;
      //   this.isLoading = false;
      // });
    }
  }

  close() {
    this.modalRef?.close();
  }
}
