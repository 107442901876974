import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {environment} from "../../../../../environments/environment";
import {HeaderService} from "../../../tandem-core/services/header.service";
import {ForgotPasswordDialogComponent} from "../forgot-password-dialog/forgot-password-dialog.component";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {ThemingService} from "../../../tandem-core/services/theming.service";


@Component({
  selector: 'tandem-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginForm: FormGroup = new FormGroup<any>({});

  public mode: 'login' | 'register' = 'login';

  lightMode = true;

  errorMessage: string | null = null;
  envName: string = environment.environmentName;
  preventButtonPress = false;

  whiteIconPath: string | null =  null
  primaryIconPath: string | null =  null
  logoPath: string | null =  null
  whiteLogoPath: string | null = null

  private fromRegister: string | null = null;

  themeLoaded = false;

  private brandingConfigSubscription: any;
  private userSubscription: any;
  private queryParamsSubscription: any;

  constructor(private auth: AuthService,
              private afAuth: AngularFireAuth,
              private router: Router,
              private route: ActivatedRoute,
              private dialogService: DialogService,
              private headerService: HeaderService,
              private themingService: ThemingService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.brandingConfigSubscription = this.themingService.brandingConfig.subscribe(config => {
      if (config) {
        if (config.logoURL) {
          this.logoPath = config.logoURL
        }
        this.themeLoaded = true;
      }
    })

    this.userSubscription = this.auth.$user.subscribe(user => {
      if (user) {
        this.router.navigate(['/dashboard'], { 
          queryParamsHandling: 'preserve',
          replaceUrl: true
        });
      }
    });

    this.headerService.resetConfig();
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.fromRegister = params['from'] || null;
    });
  }

  ngOnDestroy() {
    if (this.brandingConfigSubscription) {
      this.brandingConfigSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  onGoogleLogin() {

    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      this.auth.signInWithGoogle().then(res => {
        this.router.navigate(['/dashboard'])
      }).catch(err => {
        this.preventButtonPress = false;
      })
    }

    // this.checkUserExists('user@example.com').then(exists => {
    //   if (exists) {
    //   } else {
    //
    //   }
    // }).catch(error => {
    //   console.error('Error checking for user', error);
    // });
  }
  public keydownSignInWithEmail(event: KeyboardEvent) {
    if(event.code === 'Enter') {
      this.signInWithEmail();
    }
  }

  public signInWithEmail() {
    if (!this.preventButtonPress) {
      this.preventButtonPress = true;
      this.auth.signInWithEmailPass(
        this.loginForm.get('email')?.value,
        this.loginForm.get('password')?.value
      ).then(res => {
        this.preventButtonPress = false;
      }).catch(err => {
        this.preventButtonPress = false;
      })
    }
  }

    protected readonly environment = environment;

  forgotPassword() {
    this.dialogService.openModal(ForgotPasswordDialogComponent, {});
  }

  routeWithParams(s: string) {
    this.router.navigate([s], {
      queryParamsHandling: 'preserve'
    })
  }

  checkUserExists(email: string): Promise<boolean> {
    return this.afAuth.fetchSignInMethodsForEmail(email)
      .then(signInMethods => {
        // If the signInMethods array is not empty, a user exists with the given email
        return signInMethods.length > 0;
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error checking for user', error);
        throw error;
      });
  }

  goToRegister() {
    let targetRoute: string;

    if (this.fromRegister === 'coach') {
      targetRoute = 'auth/coach-registration';
    } else if (this.fromRegister === 'user') {
      targetRoute = 'auth/new-registration';
    } else {
      targetRoute = 'auth/coach-registration';
    }

    // Navigate to the target route and remove the 'from' query parameter
    this.router.navigate([targetRoute], {
      queryParams: { from: null },
      queryParamsHandling: 'merge'
    });
  }
}
