import {AfterViewInit, Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, NgIf} from "@angular/common";
import {NgxEchartsDirective, NgxEchartsModule} from "ngx-echarts";
import {EChartsOption} from "echarts";
import {InvestmentDataPoint} from "../investment/investment.component";
import {DebtDataPoint} from "../debt-payoff/debt-payoff.component";
import {RouterLink} from "@angular/router";
import {ChartService} from "../../../../../tandem-core/services/chart.service";
import {ThemingService} from "../../../../../tandem-core/services/theming.service";

export interface NetWorthDataPoint {
  period: number;
  months: number;
  dateLabel: Date;
  age: number;
  investmentBalance: number;
  debtBalance: number;
  netWorth: number;
}

@Component({
  selector: 'app-debt-vs-invest',
  standalone: true,
  imports: [
    NgIf,
    NgxEchartsModule,
    RouterLink
  ],
  providers: [
  ],
  templateUrl: './debt-vs-invest.component.html',
  styleUrls: ['./debt-vs-invest.component.scss']
})
export class DebtVsInvestComponent implements OnInit {

  @Input() useCase: string = 'general';

  frequencyConversionMap: Map<number, number> = new Map([
    [ 52, 12 / 52 ],  // Weekly
    [ 26, 12 / 26 ],  // Biweekly
    [ 12, 1 ],        // Monthly
    [ 4, 3 ],         // Quarterly
    [ 2, 6 ],         // Semiannually
    [ 1, 12 ]         // Annually
  ]);

  investmentDataPoints: InvestmentDataPoint[] = [];
  debtDataPoints: DebtDataPoint[] = [];
  netWorthDataPoints: NetWorthDataPoint[] = [];
  monthLabels: string[] = [];
  monthChartLabels: string[] = [];

  investmentChartData: number[] = [];

  lastInvestmentPoint: InvestmentDataPoint | null = null;
  lastDebtPoint: DebtDataPoint | null = null;
  lastNetWorthPoint: NetWorthDataPoint | null = null;

  chartOptions?: EChartsOption;
  private currentTheme: string = '#0684ff';

  constructor(private chartService: ChartService, @Inject(PLATFORM_ID) private platformId: Object, private themeService: ThemingService) {
  }
   ngOnInit(): void {
   this.themeService.selectedTheme.subscribe((theme: string) => {
      this.currentTheme = theme;
      this.renderLineChart();
    });
    this.renderLineChart();
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public renderLineChart() {
    this.calculateInvestmentData();
    this.calculateDebtData();
    this.calculateNetWorthData();

    this.chartOptions = this.chartService.generateLineChart('Date', 'Value', this.monthLabels, [
      {name: 'Investment Balance', data: this.investmentDataPoints.map(t => t.endingBalance), color: `black`},
      {name: 'Debt Balance', data: this.debtDataPoints.map(t => t.endingBalance), color: `red`},
      {name: 'Net Worth', data: this.netWorthDataPoints.map(t => t.netWorth), color: this.currentTheme},
    ]);
  }

  private calculateInvestmentData() {
    const currentAge: number = 21;
    const targetAge: number = 32;
    const annualReturn: number = 0.085;
    const paymentFrequency: number = 12;

    const startingInvestmentBalance: number = 4000;
    const initialContributionAmount: number = 0;
    const initialContributionToInvestment: number = 0;
    const ongoingAvailableAmount: number = 1000;
    const ongoingPercentToInvestment: number = 0.35;

    let results = this.chartService.generateInvestmentDataPoints(
      currentAge, targetAge, startingInvestmentBalance, initialContributionToInvestment * initialContributionAmount, ongoingAvailableAmount * ongoingPercentToInvestment, annualReturn, paymentFrequency, this.investmentDataPoints, this.lastInvestmentPoint, this.monthLabels
    );
    this.investmentDataPoints = results.data;
    this.lastInvestmentPoint = results.lastDataPoint;
    this.monthLabels = results.monthLabels;
  }

  private calculateDebtData() {
    const startingDebtBalance: number = 40000;
    const annualInterestRate: number = 0.045;
    const initialContributionAmount: number = 0;
    const initialContributionToDebt: number = 0;
    const ongoingAvailableAmount: number = 1000;
    const ongoingPercentToDebt: number = 0.65
    const paymentFrequency: number = 12

    let results = this.chartService.generateDebtDataPoints(
      startingDebtBalance, annualInterestRate, initialContributionToDebt * initialContributionAmount, ongoingAvailableAmount * ongoingPercentToDebt, paymentFrequency, this.debtDataPoints, this.lastDebtPoint, this.monthLabels, this.investmentDataPoints.length - 1
    );

    if (results.monthLabels.length > this.monthLabels.length) {
      this.monthLabels = results.monthLabels;
    }
    this.debtDataPoints = results.data
    this.lastDebtPoint = results.lastDataPoint;
  }

  private calculateNetWorthData() {
    this.netWorthDataPoints = [];
    if (this.investmentDataPoints.length >= this.debtDataPoints.length) {
      this.investmentDataPoints.forEach((val, index, array) => {
        this.netWorthDataPoints.push({
          period: val.period,
          age: val.age,
          dateLabel: val.dateLabel,
          months: val.months,
          investmentBalance: val.endingBalance,
          debtBalance: this.debtDataPoints[index]?.endingBalance,
          netWorth: val.endingBalance - (this.debtDataPoints[index]?.endingBalance | 0)
        });
      });
    } else {
      this.debtDataPoints.forEach((val, index, array) => {
        this.netWorthDataPoints.push({
          period: val.period,
          age: this.investmentDataPoints[index]?.age,
          dateLabel: val.dateLabel,
          months: val.months,
          investmentBalance: this.investmentDataPoints[index]?.endingBalance,
          debtBalance: val.endingBalance,
          netWorth: this.investmentDataPoints[index]?.endingBalance - val.endingBalance
        })
      })
    }
  }

  public generateDebtDataPoints(beginningBalance: number,
                                annualInterestRate: number,
                                initialContribution: number,
                                payment: number,
                                paymentFrequency: number,
                                data: DebtDataPoint[],
                                lastDataPoint: DebtDataPoint | null,
                                monthLabels: string[], maxNumberOfPoints = 1000): {data: DebtDataPoint[], lastDataPoint: DebtDataPoint | null, monthLabels: string[]} {
    const currentDate = new Date();

    data = [];
    monthLabels = [`${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`]

    const firstPoint: DebtDataPoint = {period: 0, months: 0, dateLabel: new Date(), beginningBalance: beginningBalance, payment: initialContribution, interest: 0, principal: 0, endingBalance: beginningBalance - initialContribution};
    data.push(firstPoint);
    lastDataPoint = firstPoint;

    let iterations = 0;

    while (lastDataPoint!.endingBalance > 0 && iterations < maxNumberOfPoints) {

      let interest: number = lastDataPoint.endingBalance * (annualInterestRate / paymentFrequency);
      let principal: number = payment - interest;
      let months = lastDataPoint.months + this.frequencyConversionMap.get(paymentFrequency)!;

      const labelDate = new Date();
      labelDate.setMonth(labelDate.getMonth() + months);
      monthLabels.push(`${labelDate.getMonth() + 1}/${labelDate.getFullYear()}`);

      const dataPoint:DebtDataPoint = {
        period: ++lastDataPoint.period,
        months: months,
        dateLabel: labelDate,
        beginningBalance: lastDataPoint.endingBalance,
        payment: payment,
        interest: interest,
        principal: principal,
        endingBalance: lastDataPoint.endingBalance - principal
      };

      if (dataPoint.endingBalance < 0) {
        dataPoint.endingBalance = 0;
      }

      data.push(dataPoint);
      lastDataPoint = dataPoint;

      if (iterations++ === maxNumberOfPoints) {
        console.warn("Maximum iterations reached. Please review your logic.");
      }
    }
    return {
      data, lastDataPoint, monthLabels
    };
  }
}
