import {AfterViewInit, Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {isPlatformBrowser, NgIf} from "@angular/common";
import {NgxEchartsDirective, NgxEchartsModule} from "ngx-echarts";
import {EChartsOption} from "echarts";
import {RouterLink} from "@angular/router";
import {ChartService} from "../../../../../tandem-core/services/chart.service";
import {ThemingService} from "../../../../../tandem-core/services/theming.service";


export interface InvestmentDataPoint {
  period: number;
  months: number;
  dateLabel: Date;
  age: number;
  beginningBalance: number;
  contribution: number;
  capitalGains: number;
  endingBalance: number;
}

@Component({
  selector: 'app-investment',
  standalone: true,
  imports: [
    NgIf,
    NgxEchartsModule,
    RouterLink
  ],
  providers: [
  ],
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent implements OnInit {

  @Input() useCase: string = 'general';

  dataPoints: InvestmentDataPoint[] = [];
  lastDataPoint: InvestmentDataPoint | null = null;
  monthLabels: string[] = [];

  chartOptions?: EChartsOption;
  private currentTheme: string = '#0684ff';

  constructor(private chartService: ChartService, @Inject(PLATFORM_ID) private platformId: Object, private themeService: ThemingService) {
  }

   ngOnInit(): void {
    this.themeService.selectedTheme.subscribe((theme: string) => {
      this.currentTheme = theme;
      this.renderLineChart();
    });
    this.renderLineChart();
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public renderLineChart() {
    this.calculateDataPoints();
    this.chartOptions = this.chartService.generateLineChart('Date', 'Value', this.monthLabels, [
      {name: 'Investment Data', data: this.dataPoints.map(t => t.endingBalance), color: this.currentTheme}]);
  }

  private calculateDataPoints() {
    const currentAge: number = 21
    const targetAge: number = 40
    const startingBalance: number = 4000
    const investment: number = 500
    const annualReturn: number = 0.085
    const investmentFrequency: number = 12

    let results = this.chartService.generateInvestmentDataPoints(
      currentAge, targetAge, 0, startingBalance, investment, annualReturn, investmentFrequency, this.dataPoints, this.lastDataPoint, this.monthLabels
    );

    this.dataPoints = results.data;
    this.lastDataPoint = results.lastDataPoint;
    this.monthLabels = results.monthLabels;
  }
}
