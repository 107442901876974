import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {UnauthorizedComponent} from "./components/unauthorized/unauthorized.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {EmailNotVerifiedComponent} from "./components/email-not-verified/email-not-verified.component";
import {RegisterComponent} from "./components/register/register.component";
import {VerifyEmailComponent} from "./components/verify-email/verify-email.component";
import {AdditionalInfoComponent} from "./components/additional-info/additional-info.component";
import {TestingComponent} from "./components/testing/testing.component";
import {CoachRegisterComponent} from "./components/coach-register/coach-register.component";
import {AuthGuard} from "./guards/auth-guard";
import {NewRegistrationComponent} from "./components/new-registration/new-registration.component";
import {NewUserLoginComponent} from "./components/new-user-login/new-user-login.component";
import {NewCoachRegistrationComponent} from "./components/new-coach-registration/new-coach-registration.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // Public routes (no auth required)
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
    data: { requiresAuth: false }
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AuthGuard],
    data: { requiresAuth: false }
  },
  {
    path: 'new-coach-registration',
    component: NewCoachRegistrationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'new-registration',
    component: NewRegistrationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'new-login',
    component: NewUserLoginComponent,
  },
  {
    path: 'coach-registration',
    component: CoachRegisterComponent,
    canActivate: [AuthGuard],
    data: { requiresAuth: false }
  },
  // Account setup routes
  {
    path: 'email-not-verified',  // This will be /auth/email-not-verified
    component: EmailNotVerifiedComponent,
    canActivate: [AuthGuard],
    data: { requiresAuth: true }
  },
  {
    path: 'additional-info',     // This will be /auth/additional-info
    component: AdditionalInfoComponent,
    canActivate: [AuthGuard],
    data: { requiresAuth: true }
  },
  // Other auth routes
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { requiresAuth: true }
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
  },
  {
    path: 'testing',
    component: TestingComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
