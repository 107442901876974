<!--<div class="w-full flex flex-col text-center items-center" *ngIf="isBrowser && useCase !== 'blog'">-->
<!--  <div *ngIf="isBrowser" class="w-90vw h-50vh sm:w-full sm:h-45vh md:w-60vw md:h-40vh lg:w-60vw 2xl:w-50vw 2xl:h-40vh 3xl:w-35vw" echarts [options]="chartOption"></div>-->
<!--  <h2 *ngIf="isBrowser" class="text-2xl text-primary-900 text-center">{{getTitle()}}</h2>-->
<!--</div>-->

<div class="w-fit flex flex-col text-center items-center" *ngIf="isBrowser && useCase === 'blog'">
  <div *ngIf="isBrowser" class="w-full h-[70rem]" echarts [options]="chartOption"></div>
  <h2 *ngIf="isBrowser" class="text-2xl text-primary-900 text-center text-white/0">{{getTitle()}}</h2>
</div>

<div class="w-full flex flex-col text-center items-center" *ngIf="isBrowser && useCase === 'landing'">
  <div *ngIf="isBrowser" class="w-full h-[20rem]" echarts [options]="chartOption"></div>
  <h2 *ngIf="isBrowser" class="text-2xl text-primary-900 text-center text-white/0">{{getTitle()}}</h2>
</div>
