import { Component } from '@angular/core';

@Component({
  selector: 'app-lead-magnet',
  standalone: true,
  imports: [],
  templateUrl: './lead-magnet.component.html',
  styleUrls: ['./lead-magnet.component.scss']
})
export class LeadMagnetComponent {

}
