import { Injectable } from '@angular/core';
import {AbstractDatabaseService} from "../../tandem-core/services/abstract-database.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FinancialPositionTemplate} from "../models/financial-position-template";
import {TandemUser} from "../../auth/models/tandem-user";
import {Observable, of, switchMap} from "rxjs";
import {Timestamp} from "firebase/firestore";
import {FinancialPosition} from "../models/financial-position";

@Injectable({
  providedIn: 'root'
})
export class FinancialPositionTemplateService extends AbstractDatabaseService<FinancialPositionTemplate> {

  private DEFAULT_TEMPLATE: FinancialPositionTemplate = {
    assets: [
      {
        name: 'Cash',
        hoverHint: 'Liquid funds readily available for use'
      },
      {
        name: 'Emergency Funds',
        hoverHint: 'Money set aside for a rainy day, ideally in a high-yield cash account'
      },
      {
        name: 'Financial Securities',
        hoverHint: 'Financial products such as stocks, bonds, mutual funds, and ETFs'
      },
      {
        name: 'Retirement & Tax Deferred',
        hoverHint: 'Assets in accounts designed for retirement or tax deferment purposes'
      },
      {
        name: 'Real Estate',
        hoverHint: 'Real assets such as land and buildings'
      },
      {
        name: 'Business',
        hoverHint: 'Assets your business owns such as business bank accounts, inventory, and intellectual property'
      },
      {
        name: 'Personal & Other',
        hoverHint: 'Other assets you own with marketable value such as a vehicle, boat, or collectible items'
      },
    ],
    liabilities: [
      {
        name: 'Credit Card Debt',
        hoverHint: 'Short-term unsecured debt, typically with high interest rates'
      },
      {
        name: 'Student Debt',
        hoverHint: 'Funds borrowed to pay for higher education'
      },
      {
        name: 'Real Estate Debt / Mortgages',
        hoverHint: 'Debt secured by a real asset'
      },
      {
        name: 'Business Debt',
        hoverHint: 'Funds borrowed to support the growth of a business'
      },
      {
        name: 'Personal Loans & Other Liabilities',
        hoverHint: 'Other amounts owed such as a personal loan'
      },
    ],
    assetDetails: {},
    liabilityDetails: {},
    assetDescription: 'Something you own that has value',
    liabilityDescription: 'A monetary amount you owe',
    customDescription: 'A comprehensive summary of your financial status, encompassing all of your assets and liabilities to present a clear picture of your total net worth',
    customName: 'Financial Position',
    customNamePlural: 'Financial Positions',
    coachId: '',
    userId: '',
    dateCreated: Timestamp.now(),
    dateModified: Timestamp.now()
  };
  constructor(protected override afs: AngularFirestore) {
    super('financialPositionTemplates', afs);
  }

  public getTemplateForUser(user: TandemUser): Observable<FinancialPositionTemplate> {
    if (user.financialPositionTemplateId) {
      return this.get(user.financialPositionTemplateId);
    } else if (user.coachId) {
      return this.getTemplateByCoachId(user.coachId);
    } else {
      return this.getDefaultTemplate();
    }
  }

  public generateDefaultPosition(): FinancialPosition {
    const currentTimestamp = Timestamp.now();
    return {
      dateCreated: currentTimestamp,
      dateModified: currentTimestamp,
      title: `${currentTimestamp.toDate().toDateString()}`,
      positionDate: currentTimestamp,
      userId: '',
      netWorth: 0,
      assets: this.DEFAULT_TEMPLATE.assets.map(ta => {
        return {name: ta.name, assets: [], hoverHint: ta.hoverHint}
      }),
      liabilities: this.DEFAULT_TEMPLATE.liabilities.map(tl => {
        return {name: tl.name, liabilities: [], hoverHint: tl.hoverHint}
      }),
      assetDetails: this.DEFAULT_TEMPLATE.assetDetails,
      liabilityDetails: this.DEFAULT_TEMPLATE.liabilityDetails,
      averageReturn: 0,
      averageInterestRate: 0,
      liquidAssets: 0,
      shortTermLiabilities: 0,
      totalAssets: 0,
      totalLiabilities: 0,
      totalMinimumPayment: 0,
      visibleToCoach: true,
      assetDescription: this.DEFAULT_TEMPLATE.assetDescription,
      liabilityDescription: this.DEFAULT_TEMPLATE.liabilityDescription,
    }
  }

  public generateDefaultTemplate(userId: string): FinancialPositionTemplate {
    let template = this.DEFAULT_TEMPLATE;
    template.userId = userId;
    return template;
  }

  private getTemplateByCoachId(coachId: string): Observable<FinancialPositionTemplate> {
    return this.afs.collection<FinancialPositionTemplate>('financialPositionTemplates', ref => ref.where('coachId', '==', coachId)).valueChanges().pipe(
      switchMap(templates => {
        if (templates.length > 0) {
          return of(templates[0]);
        } else {
          return this.getDefaultTemplate();
        }
      })
    );
  }

  private getDefaultTemplate(): Observable<FinancialPositionTemplate> {
    return of(this.DEFAULT_TEMPLATE);
  }
}
