<tandem-loading-container [isLoaded]="authInitialized && themeLoaded">
  <div class="flex min-h-[100vh] flex-col justify-center py-12 px-5 sm:px-6 lg:px-8 relative bg-primary">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img [src]="logoPath" class="mx-auto h-40 w-auto">
      <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-50">{{activeTab === 'auth' ? 'Sign up for an account' : activeTab === 'verify' ? 'Verify your email' : 'Enter additional info'}}</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 shadow rounded-lg sm:px-12">
        <ng-container *ngIf="activeTab === 'auth'">
          <form class="space-y-6">
            <tandem-form-input [form]="authForm" controlName="email" label="Email Address" placeholder="Email Address" inputType="email"></tandem-form-input>
            <tandem-form-input [form]="authForm" controlName="password" label="Password" placeholder="Password" inputType="password" [hideErrorMessage]="true"></tandem-form-input>
            <div *ngIf="passwordErrorMessage" class="text-red-500 text-sm mt-1">
              {{ passwordErrorMessage }}
            </div>
            <div>
              <tandem-button class="w-full mt-2" [onClick]="registerWithEmail.bind(this)" [disabled]="authForm.invalid || preventButtonPress || !!passwordErrorMessage">Sign Up</tandem-button>
            </div>
          </form>

          <div>
            <div class="relative mt-10">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-200"></div>
              </div>
              <div class="relative flex justify-center text-sm font-medium leading-6">
                <span class="bg-white px-6 text-gray-900">Or continue with</span>
              </div>
            </div>

            <div class="mt-6 flex justify-center gap-4">
              <a (click)="onGoogleLogin()" class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:cursor-pointer focus-visible:ring-transparent">
                <svg class="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z" fill="#EA4335" />
                  <path d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z" fill="#4285F4" />
                  <path d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z" fill="#FBBC05" />
                  <path d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z" fill="#34A853" />
                </svg>
                <span class="text-sm font-semibold leading-6">Google</span>
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="activeTab === 'verify'">
          <div class="flex flex-col items-center justify-center gap-4">
            <h4>Thank you for signing up! We're excited to have you as part of our community, and we hope you're excited
              to be here!
              To complete your registration and ensure the security of your account, we've sent a verification link to
              the email address you provided during sign-up. After clicking that link, come back to this screen. You
              might have to refresh your screen after verifying.</h4>
            <div class="my-8 flex flex-col gap-4">
              <h4>Didn't receive an email? </h4>
              <div class="flex flex-row justify-center">
                <tandem-button class="inline-block" theme="outline" color="primary" [onClick]="resend.bind(this)">Resend
                  verification
                </tandem-button>
                <!--        <tandem-button class="inline-block" theme="solid" color="primary" [onClick]="refresh.bind(this)">Refresh</tandem-button>-->
              </div>
            </div>

            <div class="flex flex-row justify-center items-center gap-4">
              <h5>Need to use a different email?</h5>
              <tandem-button theme="solid" color="primary">Logout</tandem-button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="activeTab === 'info'">
          <h4 class="w-full text-center mb-8">Almost done! We just need a bit more information about you in order to provide you with the best experience. Please fill out the form below.</h4>
          <div class="my-6 flex flex-row rounded-lg bg-gray-200 gap-2">
            <button class="{{coachModel === 'coachRevShare' ? 'text-white bg-primary' : 'text-black bg-gray-200'}} flex-grow rounded-lg" (click)="setCoachModel('coachRevShare')">Revenue Sharing</button>
            <button class="{{coachModel === 'coachLeadGen' ? 'text-white bg-primary' : 'text-black bg-gray-200'}} flex-grow rounded-lg" (click)="setCoachModel('coachLeadGen')">Lead Generation</button>
          </div>
          <form class="space-y-6">
            <tandem-form-input [form]="infoForm"
                               [label]="'First Name'"
                               [controlName]="'firstName'"
                               [inputId]="'firstName'"
                               [placeholder]="'First Name'"></tandem-form-input>
            <tandem-form-input [form]="infoForm"
                               [label]="'Last Name'"
                               [controlName]="'lastName'"
                               [inputId]="'lastName'"
                               [placeholder]="'Last Name'"></tandem-form-input>
            <tandem-form-input [form]="infoForm"
                               [label]="'Phone Number'"
                               [controlName]="'phoneNumber'"
                               [inputId]="'phoneNumber'"
                               [placeholder]="'Phone Number'"
                               [maskType]="'phone'"></tandem-form-input>
            <tandem-form-input [form]="infoForm"
                               [label]="'Business Name'"
                               [controlName]="'coachBusinessName'"
                               [inputId]="'coachBusinessName'"
                               [placeholder]="'Business Name'"></tandem-form-input>
            <div>
              <tandem-button class="w-full mt-2" [onClick]="submitInfo.bind(this)" [disabled]="infoForm.invalid" color="primary">Submit</tandem-button>
            </div>
          </form>
        </ng-container>

      </div>

      <p *ngIf="activeTab === 'auth'" class="mt-10 text-center text-sm text-gray-50">
        Already a member?
        <a class="font-semibold leading-6 hover:cursor-pointer" (click)="goToLogin()">Sign In</a>
      </p>
    </div>
    <tandem-button *ngIf="activeTab === 'auth'" color="white" icon="down-circle" class="w-fit mx-auto mt-6" [onClick]="scrollToElement.bind(this)">Learn More</tandem-button>
    <!--    <button type="button" class=" w-20 h-16 mx-auto mt-8 rounded-full  p-2 text-white shadow-sm flex items-center">-->
    <!--      Learn More-->
    <!--      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 w-20 h-16">-->
    <!--        <path stroke-linecap="round" stroke-linejoin="round" d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />-->
    <!--      </svg>-->
    <!--    </button>-->
  </div>
</tandem-loading-container>
