import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import {AuthRoutingModule} from "./auth-routing.module";
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthDirective } from './directives/auth.directive';
import { ProfileComponent } from './components/profile/profile.component';
import {TandemCoreModule} from "../tandem-core/tandem-core.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { EmailNotVerifiedComponent } from './components/email-not-verified/email-not-verified.component';
import { ChangeEmailDialogComponent } from './components/change-email-dialog/change-email-dialog.component';
import { ReauthWithEmailPassComponent } from './components/reauth-with-email-pass/reauth-with-email-pass.component';
import {HttpClientModule} from "@angular/common/http";
import {PlaidModule} from "../plaid/plaid.module";
import {StripeModule} from "../stripe/stripe.module";
import { RegisterComponent } from './components/register/register.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AdditionalInfoComponent } from './components/additional-info/additional-info.component';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';
import { AttemptAuthDialogComponent } from './components/attempt-auth-dialog/attempt-auth-dialog.component';
import { TestingComponent } from './components/testing/testing.component';
import { UserSubManagementComponent } from './components/user-sub-management/user-sub-management.component';
import {TandemDatePipe} from "../tandem-core/directives/tandem-date.pipe";
import {CoachRegisterComponent} from "./components/coach-register/coach-register.component";
import { NewRegistrationComponent } from './components/new-registration/new-registration.component';
import { NewUserLoginComponent } from './components/new-user-login/new-user-login.component';
import { NewCoachRegistrationComponent } from './components/new-coach-registration/new-coach-registration.component';
import {LeadMagnetComponent} from "./components/lead-magnet/lead-magnet.component";
import {DebtPayoffComponent} from "./components/landing/calculators/debt-payoff/debt-payoff.component";
import {InvestmentComponent} from "./components/landing/calculators/investment/investment.component";
import {DebtVsInvestComponent} from "./components/landing/calculators/debt-vs-invest/debt-vs-invest.component";
import {
  FinancialPositionGraphComponent
} from "./components/landing/graphs/financial-position-graph/financial-position-graph.component";
import {CashFlowGraphComponent} from "./components/landing/graphs/cash-flow-graph/cash-flow-graph.component";
import {
  SpendingPlanGraphComponent
} from "./components/landing/graphs/spending-plan-graph/spending-plan-graph.component";
import {GoalCardComponent} from "./components/landing/goal-card/goal-card.component";



@NgModule({
  declarations: [
    LoginComponent,
    UnauthorizedComponent,
    AuthDirective,
    AuthDirective,
    ProfileComponent,
    EmailNotVerifiedComponent,
    ChangeEmailDialogComponent,
    ReauthWithEmailPassComponent,
    RegisterComponent,
    VerifyEmailComponent,
    AdditionalInfoComponent,
    ForgotPasswordDialogComponent,
    AttemptAuthDialogComponent,
    TestingComponent,
    UserSubManagementComponent,
    CoachRegisterComponent,
    NewRegistrationComponent,
    NewUserLoginComponent,
    NewCoachRegistrationComponent
  ],
  exports: [
    AuthDirective
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    TandemCoreModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    HttpClientModule,
    PlaidModule,
    StripeModule,
    FormsModule,
    LeadMagnetComponent,
    DebtPayoffComponent,
    InvestmentComponent,
    DebtVsInvestComponent,
    FinancialPositionGraphComponent,
    CashFlowGraphComponent,
    SpendingPlanGraphComponent,
    GoalCardComponent,
  ],
  providers: [
    TandemDatePipe
  ]
})
export class AuthModule { }
