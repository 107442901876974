import { Component } from '@angular/core';

@Component({
  selector: 'tandem-new-user-login',
  templateUrl: './new-user-login.component.html',
  styleUrls: ['./new-user-login.component.scss']
})
export class NewUserLoginComponent {

}
