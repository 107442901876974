import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, UntypedFormGroup} from "@angular/forms";
import {Timestamp} from "firebase/firestore";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {LeadMagnetComponent} from "../../lead-magnet/lead-magnet.component";
import {FormCheckboxComponent} from "../../../../tandem-core/components/forms/form-checkbox/form-checkbox.component";
import {TandemCoreModule} from "../../../../tandem-core/tandem-core.module";
import {ThemingService} from "../../../../tandem-core/services/theming.service";

export interface Goal {
  id?: string;
  name: string;
  type: 'Short-Term' | 'Mid-Term' | 'Long-Term';
  completed: boolean;
  userId: string;
  startDate: Timestamp;
  targetDate: Timestamp;
}

@Component({
  selector: 'app-goal-card',
  standalone: true,
  imports: [
    TandemCoreModule,
    NgClass,
    NgForOf,
    NgIf,
    LeadMagnetComponent
  ],
  providers:[
    DatePipe,
  ],
  templateUrl: './goal-card.component.html',
  styleUrls: ['./goal-card.component.scss']
})
export class GoalCardComponent implements OnInit, OnChanges {

  @Input() goalType: 'Short-Term' | 'Mid-Term' | 'Long-Term' = 'Short-Term';
  @Input() userId: string = '';

  @Input() editable: boolean = true;

  @Output() goalCreated: EventEmitter<Goal> = new EventEmitter<Goal>();
  @Output() goalUpdated: EventEmitter<Goal> = new EventEmitter<Goal>();
  @Output() goalDeleted: EventEmitter<Goal> = new EventEmitter<Goal>();
  @Output() sortGoals: EventEmitter<'Short-Term' | 'Mid-Term' | 'Long-Term'> = new EventEmitter<'Short-Term' | 'Mid-Term' | 'Long-Term'>();

  goals: Goal[] = [
    {
      id: '1',
      userId: '1',
      completed: false,
      name: 'Crush my debt',
      startDate: Timestamp.fromDate(new Date("2024/03/01")),
      targetDate: Timestamp.fromDate(new Date("2024/05/01")),
      type: 'Short-Term'
    },
    {
      id: '2',
      userId: '2',
      completed: false,
      name: 'Gain Financial Freedom',
      startDate: Timestamp.fromDate(new Date("2024/04/01")),
      targetDate: Timestamp.fromDate(new Date("2024/06/01")),
      type: 'Short-Term'
    },
    {
      id: '3',
      userId: '3',
      completed: true,
      name: 'Crush my debt',
      startDate: Timestamp.fromDate(new Date("2024/03/01")),
      targetDate: Timestamp.fromDate(new Date("2024/05/01")),
      type: 'Short-Term'
    },
    {
      id: '4',
      userId: '4',
      completed: true,
      name: 'Move my money',
      startDate: Timestamp.fromDate(new Date("2024/03/01")),
      targetDate: Timestamp.fromDate(new Date("2024/04/01")),
      type: 'Short-Term'
    },
  ];

  checksForm = new UntypedFormGroup({});

  constructor(private themeService: ThemingService, private fb: FormBuilder, private datePipe: DatePipe) {
  }

   ngOnInit(): void {
    this.checksForm = this.fb.group({
      goals: this.fb.array(this.goals.map(goal => this.fb.control(goal.completed)))
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // TODO add check for if the goals were the thing being updated
    console.log(this.goals)
    this.checksForm = this.fb.group({
      goals: this.fb.array(this.goals.map(goal => this.fb.control(goal.completed)))
    });
  }

  get goalsFormArray() {
    return this.checksForm.get('goals') as FormArray;
  }
  sortGoal() {
    this.sortGoals.emit(this.goalType)
  }

  getControl(goalControl: AbstractControl<any>) {
    return goalControl as FormControl;
  }


  getGoalCompletion(): string {
    let completed = this.goals.filter(g => g.completed).length;
    return `${completed} / ${this.goals.length} completed`
  }

  goalIsExpired(index: number) {
    return !this.goals[index].completed && this.goals[index].targetDate.toDate() < new Date();
  }

  onToggle(newValue: boolean, goal: Goal) {
    goal.completed = newValue;
    this.goalUpdated.emit(goal);
  }

  getDateString(i: number) {
    return `Started: ${this.formatDate(this.goals[i]!.startDate!.toDate())} | Target: ${this.formatDate(this.goals[i].targetDate.toDate())}`
  }

  getLabelForGoal(i: number) {
    return this.goals[i].name
  }

  formatDate(date: Date): string {
    let formatted = this.datePipe.transform(date);
    if (formatted) {
      return formatted;
    }
    return date.toDateString();
  }
}
