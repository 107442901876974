import { Injectable } from '@angular/core';
import {
  ArcElement,
  CategoryScale,
  Chart,
  ChartData,
  DoughnutController,
  Legend,
  LegendItem,
  Title,
  Tooltip
} from "chart.js";
import {CurrencyService} from "./currency.service";
import {EChartsOption} from "echarts";
import {DecimalPipe} from "@angular/common";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {InvestmentDataPoint} from "../../calculators/models/investment-data-point";
import {DebtDataPoint} from "../../calculators/models/debt-data-point";

export interface DoughnutChartData {
  labels: string[];
  datasets: {
    label: string;
    data: any[];
    backgroundColor?: string[];
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  SHADING_FACTOR: number = 1.7; // Adjust this factor to change the step difference

  constructor(private currencyService: CurrencyService, private breakpointObserver: BreakpointObserver,
              private decimalPipe: DecimalPipe) {
    Chart.register(DoughnutController, ArcElement, CategoryScale, Title, Tooltip, Legend);
  }

  public generateLineChart(xAxis: string, yAxis: string, labels: string[], dataSeries: {name: string, data: number[], color: string; disableByDefault?: boolean}[]): EChartsOption {
    let dataRanges: any[] = [];

    dataSeries.forEach(series => {
      dataRanges.push({
        name: series.name,
        data: series.data,
        type: 'line',
        smooth: true,
        symbolSize: 0,
        itemStyle: {
          color: series.color, // Assuming each series object has a color property
        },
        label: {
          show: false,
          position: 'top',
        },
      })
    })

    return {
      renderer: 'svg',
      xAxis: {
        type: 'category',
        data: labels,
        name: xAxis,
        nameLocation: 'middle',
        nameGap: 45,
      },
      yAxis: {
        type: 'value',
        name: yAxis,
        nameLocation: 'middle',
        nameGap: 75,
        nameRotate: 90,
        axisLabel: {
          formatter: (value: any) => `$${this.decimalPipe.transform(value)}`
        }
      },
      grid: {
        left: '5%', // or a small value like '10'
        right: '5%', // or a small value
        top: '10%', // or a small value
        bottom: this.breakpointObserver.isMatched(Breakpoints.Handset) ? '30%' : '20%', // or a small value
        containLabel: true // This ensures the labels are still visible within the container
      },
      legend: {
        show: true, // Set to true to display the legend
        data: dataSeries.map(ds => ds.name), // Names of the series
        left: 'center', // You can position the legend in the center
        top: '0', // You can position the legend at the top of the chart,
        icon: 'rect', // Use a built-in rectangle shape
        itemWidth: 14, // Width of the icon
        itemHeight: 14, // Height of the icon
        padding: 1,
        // Customize other properties like textStyle to match the series color if needed
        textStyle: {
          color: '#333', // Example text color
          // Apply additional text styles as needed
        },
        // You can customize the legend appearance further with additional properties
        selected: dataSeries.reduce((selectedObj, series) => {
          // @ts-ignore
          selectedObj[series.name] = !series.disableByDefault;
          return selectedObj;
        }, {})
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        formatter: (params) => {
          if (Array.isArray(params)) {
            // Assuming all series share the same x-axis label, we take it from the first item
            let tooltipHeader = `<strong>${params[0].name}</strong><br/>`;

            let seriesInfo = params.map((item) => {
              const seriesName = item.seriesName;
              const valueFormattedAsCurrency = `${item.data.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
              const colorDot = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>`;

              return `${colorDot}${seriesName}: ${valueFormattedAsCurrency}`;
            }).join('<br/>');

            return tooltipHeader + seriesInfo;
          }

          return '';
        }
      },
      series: dataRanges,
    }
  }

  // public generatePieChart(dataSeries: {name: string, data: { name: string, value: number }[]}): EChartsOption {
  //   return {
  //     tooltip: {
  //       trigger: 'item',
  //       formatter: '{a} <br/>{b}: {c} ({d}%)'
  //     },
  //     series: dataSeries.map(ds => {
  //       return {
  //         name: ds.name,
  //         type: 'pie',
  //         selectedMode: 'single',
  //         radius: dataSeries.indexOf(ds) === 0 ? [0, '55%'] : ['70%', '85%'],
  //         label: dataSeries.indexOf(ds) === 0 ? {
  //           position: 'inner',
  //           fontSize: 14
  //         } : undefined,
  //         labelLine: {
  //           length: 30,
  //           show: dataSeries.indexOf(ds) !== 0
  //         },
  //         data: ds.data
  //       }
  //     })
  //   };
  // }

  public generatePieChart(
    innerSeries: {name: string, data: { name: string, value: number, isRevenue: boolean }[]}, 
    outerSeries: {name: string, data: { name: string, value: number, parentSection: string, isRevenue: boolean }[]}, 
    showLegend: boolean, 
    omitLabelLines: boolean
  ): EChartsOption {
    // Generate color shades for revenue (green)
    const revenueShades = this.generateShades('#4CAF50', 8);  // Green shades

    // Define base colors for expense sections by index
    const expenseBaseColors4Sections = [
      '#EAB308', // Yellow for first expense section
      '#6B7280', // Gray/Steel for second expense section
      '#F97316', // Orange for third expense section
      '#EF4444'  // Red for fourth expense section
    ];

    const expenseBaseColors3Sections = [
      '#EAB308', // Yellow for first expense section
      '#F97316', // Orange for second expense section
      '#EF4444'  // Red for third expense section
    ];

    // Determine which color array to use based on number of expense sections
    const numExpenseSections = innerSeries.data.length - 1; // Subtract 1 for revenue section

    // Define a default color array that works for any number of sections
    const defaultExpenseColors = [
      '#EF4444',  // Red
      '#F97316',  // Orange
      '#EAB308',  // Yellow
      '#6B7280',  // Gray/Steel
    ];

    // Get the appropriate expense colors based on number of sections
    const expenseBaseColors = numExpenseSections <= 1 ? 
      [defaultExpenseColors[0]] : // If only 1 expense section, use red
      numExpenseSections === 3 ? expenseBaseColors3Sections :
      numExpenseSections === 4 ? expenseBaseColors4Sections :
      defaultExpenseColors.slice(0, numExpenseSections); // For any other number, take needed colors from default array

    // Generate shades for each expense color
    const expenseShadesArray = expenseBaseColors.map(color => 
      this.generateShades(color, 8)
    );

    // Map colors to inner series data - use base colors
    const coloredInnerSeriesData = innerSeries.data.map((item, index) => {
      let color;
      if (item.isRevenue) {
        color = revenueShades[0]; // Use darkest green for revenue
      } else {
        // Get the expense section index (subtract 1 since revenue is index 0)
        const expenseIndex = index - 1;
        color = expenseBaseColors[expenseIndex] || expenseBaseColors[expenseBaseColors.length - 1]; // Fallback to last color if index out of bounds
      }
      
      return {
        name: item.name,
        value: Math.abs(item.value),
        itemStyle: {
          color: color
        }
      };
    });

    // Map colors to outer series data - use shades based on parent section
    const coloredOuterSeriesData = outerSeries.data.map((item) => {
      // Find the parent section in the inner series
      const parentSection = innerSeries.data.find(innerItem => innerItem.name === item.parentSection);
      
      let shades;
      if (parentSection?.isRevenue) { // Check if parent is revenue/asset section
        shades = revenueShades;
      } else {
        // Find the index of the parent section in the inner series
        const parentSectionIndex = innerSeries.data.findIndex(innerItem => innerItem.name === item.parentSection);
        // Get the expense section index (subtract 1 since revenue is index 0)
        const expenseIndex = parentSectionIndex - 1;
        shades = expenseShadesArray[expenseIndex] || expenseShadesArray[expenseShadesArray.length - 1];
      }
      
      // Use different shades for items in the same section
      const sectionItems = outerSeries.data.filter(i => i.parentSection === item.parentSection);
      const itemIndex = sectionItems.indexOf(item);
      const shadeIndex = Math.min(itemIndex, shades.length - 1);

      return {
        name: item.name,
        value: Math.abs(item.value),
        itemStyle: {
          color: shades[shadeIndex]
        }
      };
    });

    return {
      legend: {
        show: showLegend,
        orient: 'vertical',
        right: '5%', 
        top: 'middle',
        align: 'left',
        data: innerSeries.data.map(item => item.name),
        backgroundColor: '#f5f5f5',
        borderRadius: 5,
        padding: 10,
        itemGap: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        selectedMode: false,
      },
      grid: {
        right: '90%',
        containLabel: true
      },
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          const valueFormatted = currencyFormatter.format(params.value);
          return `${params.name}: ${valueFormatted} (${params.percent}%)`;
        }
      },
      series: [
        {
          name: innerSeries.name,
          type: 'pie',
          center: ['50%', '50%'],
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 1
          },
          selectedMode: 'single',
          radius: [0, '55%'],
          startAngle: 90,
          clockwise: false,
          label: undefined,
          labelLine: {
            show: false
          },
          data: coloredInnerSeriesData
        },
        {
          name: outerSeries.name,
          type: 'pie',
          center: ['50%', '50%'],
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 1
          },
          radius: ['70%', '85%'],
          startAngle: 90,
          clockwise: false,
          label: undefined,
          labelLine: undefined,
          data: coloredOuterSeriesData
        }
      ]
    };
  }

  generateShades(startColor: string, count: number): string[] {
    // Convert hex to RGB
    const r = parseInt(startColor.substring(1, 3), 16);
    const g = parseInt(startColor.substring(3, 5), 16);
    const b = parseInt(startColor.substring(5, 7), 16);

    // Convert RGB to HSL
    const max = Math.max(r, g, b) / 255;
    const min = Math.min(r, g, b) / 255;
    const l = (max + min) / 2;
    const d = max - min;
    let h, s;

    if (max === min) {
      h = s = 0;
    } else {
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r / 255:
          h = (g / 255 - b / 255) / d + (g < b ? 6 : 0);
          break;
        case g / 255:
          h = (b / 255 - r / 255) / d + 2;
          break;
        default:
          h = (r / 255 - g / 255) / d + 4;
          break;
      }
      h /= 6;
    }

    // Generate shades by varying lightness
    const shades: string[] = [];
    const startLightness = l; // Start with the base color's lightness
    const maxLightness = Math.min(0.75, startLightness + 0.3); // Limit how light we go - at most 30% lighter than base

    for (let i = 0; i < count; i++) {
      // Calculate lightness for this shade - go progressively lighter
      const lightness = startLightness + ((maxLightness - startLightness) * i) / (count - 1);
      // Keep stronger saturation with more subtle reduction
      const saturation = Math.max(0.5, s * (1 - (i / count) * 0.3));
      shades.push(this.hslToHex(h * 360, saturation * 100, lightness * 100));
    }

    return shades;
  }

  private hslToHex(h: number, s: number, l: number): string {
    l /= 100;
    s /= 100;
    const a = s * Math.min(l, 1 - l);
    const f = (n: number) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  private componentToHex(c: number): string {
    const hex = Math.min(255, Math.max(0, c)).toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  private generateColorShades(baseRed: number, baseGreen: number, baseBlue: number, shadeCount: number): string[] {
    const shades: string[] = [];
    for (let i = 0; i < shadeCount; i++) {
      // Calculate the increase in green and blue components to get lighter shades
      const redValue = Math.round(baseRed + (i / shadeCount) * (255 - baseRed));
      const greenValue = Math.round(baseGreen + (i / shadeCount) * (255 - baseGreen));
      const blueValue = Math.round(baseBlue + (i / shadeCount) * (255 - baseBlue));
      shades.push(`rgb(${redValue}, ${greenValue}, ${blueValue})`);
    }
    return shades;
  }

  public generateDoughnutChart(chartId: string, centerText: {label: string, value: number}, data: any, numOuterLabels: number, omitLegend: boolean = false): Chart<"doughnut"> {

    const chartValuePlugin = {
      id: `${chartId}Plugin`,
      afterDraw: (chart: any) => {
        let ctx = chart.ctx;
        let width = chart.width,
          height = chart.height;
        ctx.restore();

        // Manually set the styles based on your Tailwind config
        const fontSizePx = omitLegend ? 24 : 36; // From fontSize['28']
        let primaryColor = 'black'; // Replace with your actual primary color value


        ctx.font = `${fontSizePx}px ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;
        ctx.fillStyle = primaryColor;

        let firstLine = centerText.label;
        let secondLine = this.currencyService.formatNumber(centerText.value);
        // if (centerText.value > 0) {
        //   ctx.fillStyle = 'green';
        // } else if (centerText.value < 0) {
        //   ctx.fillStyle = 'red';
        // }

        let firstLineX = Math.round((width - ctx.measureText(firstLine).width) / 2);
        let secondLineX = Math.round((width - ctx.measureText(secondLine).width) / 2);
        let firstLineY = height / (omitLegend ? 1.8 : 2.1) - fontSizePx; // Adjust this value to control the vertical spacing
        let secondLineY = height / (omitLegend ? 1.8 : 2.1);

        ctx.fillText(firstLine, firstLineX, firstLineY);
        ctx.fillText(secondLine, secondLineX, secondLineY);

        ctx.save();
      }
    };

    return new Chart(chartId, {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: !omitLegend,
            position: 'bottom',
            labels: {
              filter(item: LegendItem, data: ChartData): boolean {
                return data.labels!.indexOf(item.text) < numOuterLabels;
              }
            }
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function(context) {

                let label = context.dataset.label || '';
                //
                // if (context.datasetIndex === 0) {
                //   label = context.dataset.label[context.dataIndex];
                // }

                if (label) {
                  label += ': ';
                }
                if (context.parsed) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed);
                }
                return label;
              },
              title: function(context) {
                if (context[0].datasetIndex == 0) {
                  return data.labels[context[0].dataIndex + data.datasets[1].data.length]
                } else {
                  return context[0].label;
                }
              }
            }
          },
        },
      },
      plugins: [chartValuePlugin]
    })
  }

  private frequencyConversionMap: Map<number, number> = new Map([
    [ 52, 12 / 52 ],  // Weekly
    [ 26, 12 / 26 ],  // Biweekly
    [ 12, 1 ],        // Monthly
    [ 4, 3 ],         // Quarterly
    [ 2, 6 ],         // Semiannually
    [ 1, 12 ]         // Annually
  ]);

  public generateInvestmentDataPoints(currentAge: number,
                                      targetAge: number,
                                      beginningBalance: number,
                                      initialContribution: number,
                                      investment: number,
                                      annualReturn: number,
                                      paymentFrequency: number,
                                      data: InvestmentDataPoint[],
                                      lastDataPoint: InvestmentDataPoint | null,
                                      monthLabels: string[]): {data: InvestmentDataPoint[], lastDataPoint: InvestmentDataPoint | null, monthLabels: string[]} {
    const currentDate = new Date();

    data = [];
    monthLabels = [`${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`]
    const firstPoint: InvestmentDataPoint = {period: 0, months: 0, dateLabel: new Date(), age: currentAge, beginningBalance: beginningBalance, contribution: initialContribution, capitalGains: 0, endingBalance: beginningBalance + initialContribution};
    data.push(firstPoint);
    lastDataPoint = firstPoint;

    const maxIterations = 1000;  // for example
    let iterations = 0;

    while (targetAge > lastDataPoint.age && iterations < maxIterations) {
      let months = lastDataPoint.months + this.frequencyConversionMap.get(paymentFrequency)!;
      let capitalGains: number = lastDataPoint.endingBalance * (1 + (annualReturn / paymentFrequency)) - lastDataPoint.endingBalance;

      const labelDate = new Date();
      labelDate.setMonth(labelDate.getMonth() + months);
      monthLabels.push(`${labelDate.getMonth() + 1}/${labelDate.getFullYear()}`);

      const dataPoint:InvestmentDataPoint = {
        period: ++lastDataPoint.period,
        months: months,
        dateLabel: labelDate,
        age: lastDataPoint.age + ((months - lastDataPoint.months) / 12),
        beginningBalance: lastDataPoint.endingBalance,
        contribution: investment,
        capitalGains: capitalGains,
        endingBalance: lastDataPoint.endingBalance + investment + capitalGains
      };
      data.push(dataPoint);
      lastDataPoint = dataPoint;

      iterations += 1;

      if (iterations === maxIterations) {
        console.warn("Maximum iterations reached. Please review your logic.");
        break;
      }
    }

    return {
      data, lastDataPoint, monthLabels
    };
  }

  public generateDebtDataPoints(beginningBalance: number,
                                annualInterestRate: number,
                                initialContribution: number,
                                payment: number,
                                paymentFrequency: number,
                                data: DebtDataPoint[],
                                lastDataPoint: DebtDataPoint | null,
                                monthLabels: string[], maxNumberOfPoints = 1000): {data: DebtDataPoint[], lastDataPoint: DebtDataPoint | null, monthLabels: string[]} {
    const currentDate = new Date();

    data = [];
    monthLabels = [`${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`]

    const firstPoint: DebtDataPoint = {period: 0, months: 0, dateLabel: new Date(), beginningBalance: beginningBalance, payment: initialContribution, interest: 0, principal: 0, endingBalance: beginningBalance - initialContribution};
    data.push(firstPoint);
    lastDataPoint = firstPoint;

    let iterations = 0;

    while (lastDataPoint!.endingBalance > 0 && iterations < maxNumberOfPoints) {

      let interest: number = lastDataPoint.endingBalance * (annualInterestRate / paymentFrequency);
      let principal: number = payment - interest;
      let months = lastDataPoint.months + this.frequencyConversionMap.get(paymentFrequency)!;

      const labelDate = new Date();
      labelDate.setMonth(labelDate.getMonth() + months);
      monthLabels.push(`${labelDate.getMonth() + 1}/${labelDate.getFullYear()}`);

      const dataPoint:DebtDataPoint = {
        period: ++lastDataPoint.period,
        months: months,
        dateLabel: labelDate,
        beginningBalance: lastDataPoint.endingBalance,
        payment: payment,
        interest: interest,
        principal: principal,
        endingBalance: lastDataPoint.endingBalance - principal
      };

      if (dataPoint.endingBalance < 0) {
        dataPoint.endingBalance = 0;
      }

      data.push(dataPoint);
      lastDataPoint = dataPoint;

      if (iterations++ === maxNumberOfPoints) {
        console.warn("Maximum iterations reached. Please review your logic.");
      }
    }
    return {
      data, lastDataPoint, monthLabels
    };
  }
}
