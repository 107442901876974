import {AfterViewInit, Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {CurrencyPipe, DatePipe, isPlatformBrowser, NgIf, NgStyle} from '@angular/common';
import {NgxEchartsDirective, NgxEchartsModule} from 'ngx-echarts';
import {Timestamp} from "firebase/firestore";
import {ChartService} from "../../../../../tandem-core/services/chart.service";

@Component({
  selector: 'app-spending-plan-graph',
  standalone: true,
  imports: [
    NgIf,
    NgxEchartsModule
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
  ],
  templateUrl: './spending-plan-graph.component.html',
  styleUrls: ['./spending-plan-graph.component.scss']
})
export class SpendingPlanGraphComponent implements AfterViewInit {
  @Input() useCase: string = 'general';

  showLegend: boolean = true;

  chartOption:any;

  constructor(private chartService: ChartService, private currencyService: CurrencyPipe, private dateService: DatePipe, @Inject(PLATFORM_ID) private platformId: Object) {
    this.showLegend = this.useCase === 'blog';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const sections: any[] = [];
      let categories: any[] = [];
      defaultSp?.revenueSections.forEach(revSection => {
        let total = revSection.categories.reduce((accumulator, section) => accumulator + section.total, 0);
        if (total > 0) {
          sections.push({
            name: revSection.name,
            value: total
          });
        }
        revSection.categories.forEach(cat => {
          if (cat.total > 0) {
            categories.push({
              name: cat.name,
              value: cat.total
            });
          }
        });
      });
      defaultSp?.expenseSections.forEach(expenseSection => {
        let total = expenseSection.categories.reduce((accumulator, section) => accumulator + section.total, 0);
        if (total > 0) {
          sections.push({
            // name: defaultSp?.expenseSections.indexOf(expenseSection) === 0 ? 'PYF' : expenseSection.name,
            name: expenseSection.name,
            value: total
          });
        }
        expenseSection.categories.forEach(cat => {
          if (cat.total > 0) {
            categories.push({
              name: cat.name,
              value: cat.total
            });
          }
        });
      });

      const innerSeries = {
        name: 'Items',
        data: sections
      };
      const outerSeries = {
        name: 'Categories',
        data: categories,
        greenItemCount: defaultSp?.revenueSections[0].categories.filter(cat => cat.total > 0).length || 0,
        yellowItemCount: defaultSp?.expenseSections[0].categories.filter(cat => cat.total > 0).length || 0,
        orangeItemCount: defaultSp?.expenseSections[1].categories.filter(cat => cat.total > 0).length || 0,
        redItemCount: defaultSp?.expenseSections[2].categories.filter(cat => cat.total > 0).length || 0,
      };

      this.chartOption = this.chartService.generatePieChart(innerSeries,
        outerSeries,
        this.showLegend,
        false
      );
    });
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  getTitle() {
    return `Planned Cash Flow: ${this.formatNumber(defaultSp?.netCashFlow || 0)}`;
  }

  formatNumber(currencyNumber: number): string {
    let formatted = this.currencyService.transform(currencyNumber);
    if (formatted) {
      return formatted
    }
    return currencyNumber.toString();
  }

  toFirestoreTimestamp(seconds: number, nanoseconds: number) {
    return new Timestamp(seconds, nanoseconds);
  }

  formatTimestamp(timestamp: Timestamp): string {
    let formatted = this.dateService.transform(timestamp.toDate());
    if (formatted) {
      return formatted
    }
    return timestamp.toString();
  }
}

export const defaultSp = {
  "endDate": {
    "_seconds": 1701320400,
    "_nanoseconds": 0
  },
  "totalExpenses": 10084.14,
  "dateModified": {
    "_seconds": 1711315100,
    "_nanoseconds": 224000000
  },
  "revenueSections": [
    {
      "hoverHint": "Cash coming in.",
      "name": "Revenue",
      "description": "Does rev change?",
      "categories": [
        {
          "hoverHint": "Earnings from directly trading time for money whether it's in a business, job, or side hustle.",
          "total": 9200,
          "name": "Active Income",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Base Salary",
              "value": 6700
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Sales Commission",
              "value": 2500
            }
          ]
        },
        {
          "hoverHint": "Earnings in which you do not directly trade time for money.",
          "total": 650,
          "name": "Passive Income",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Rental Property Cash Flow",
              "value": 500
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Promissory Note",
              "value": 150
            }
          ]
        },
        {
          "hoverHint": "Cash flow that you don't expect to have with regularity",
          "total": 0,
          "name": "Unexpected Income",
          "items": []
        },
        {
          "total": 350,
          "hoverHint": "Cash generated from the sale of an asset or liquidation of an account.",
          "name": "Asset Liquidation",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Selling Old Jewelry",
              "value": 300
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Promissory Note Principal",
              "value": 50
            }
          ]
        }
      ]
    }
  ],
  "expenseSections": [
    {
      "hoverHint": "Expenses that pay into the future of the most important person in your life, you.",
      "name": "Pay Yourself First Expenses",
      "description": "Does PSP change?",
      "categories": [
        {
          "total": 1100,
          "hoverHint": "Income freely given to charitable or faith-inspired causes.",
          "name": "Charitable Contributions",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Prestige Worldwide",
              "value": 80
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Tithe",
              "value": 1020
            }
          ]
        },
        {
          "total": 75,
          "hoverHint": "Investments into your skills, knowledge, and network that will increase your future earning potential.",
          "name": "Investing In Yourself",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Certification Course",
              "value": 75
            }
          ]
        },
        {
          "total": 275,
          "hoverHint": "The purchase of assets that will generate wealth on your behalf such as financial securities.",
          "name": "Working Assets",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "SDIRA Contribution",
              "value": 150
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "401K Contribution",
              "value": 125
            }
          ]
        },
        {
          "hoverHint": "Payments to debt above and beyond the minimum payment.",
          "total": 3140,
          "name": "Debt Paydown",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Chase Sapphire Paydown",
              "value": 1500
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Discover Cash Back CC",
              "value": 150
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Capital One QuickSilver",
              "value": 150
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Medical Debt Paydown",
              "value": 1340
            }
          ]
        }
      ]
    },
    {
      "hoverHint": "Expenses essential to your basic survival; groceries, housing, transportation, etc.",
      "name": "Essential Expenses",
      "description": "Does EE change?",
      "categories": [
        {
          "total": 500,
          "hoverHint": "Expenses associated with having a roof over your head and a warm bed to sleep in at night.",
          "name": "Housing",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Maintenance/yard",
              "value": 500
            }
          ]
        },
        {
          "total": 200,
          "hoverHint": "Transportation expenses you need to pay for the essentials in life, such as driving to work.",
          "name": "Transportation",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Gas",
              "value": 200
            }
          ]
        },
        {
          "total": 288.99,
          "hoverHint": "Insurance and utility bills paid to maintain the essentials of life, such as the electric bill.",
          "name": "Insurance & Utilities",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Home Insurance",
              "value": 128.99
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Electricity Bill",
              "value": 90
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Gas bill",
              "value": 70
            }
          ]
        },
        {
          "hoverHint": "Food and household items necessary to sustain yourself.",
          "total": 500,
          "name": "Groceries & Toiletries",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Groceries",
              "value": 450
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Other",
              "value": 50
            }
          ]
        },
        {
          "total": 62.15,
          "hoverHint": "Necessary medical expenses such as prescriptions, check-ups, and walk-in visits.",
          "name": "Medical",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Prescriptions",
              "value": 62.15
            }
          ]
        },
        {
          "total": 0,
          "hoverHint": "Expenses such as daycare and diapers that are essential to a healthy childhood.",
          "name": "Childcare",
          "items": []
        },
        {
          "total": 2968.0300000000007,
          "hoverHint": "The minimum payments made on existing debt.",
          "name": "Debt Payments",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Cash Sapphire CC",
              "value": 100
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Discover Cash Back CC",
              "value": 15
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Capital One Quicksilver CC",
              "value": 40
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Subsidized Student Loan",
              "value": 134.96
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Unsubsidized Student Loan",
              "value": 158.51
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Parent Plus Student Loan",
              "value": 66.46
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "FHA Mortgage",
              "value": 1711.5
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Auto Loan",
              "value": 416.82
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Medical Debt",
              "value": 324.78
            }
          ]
        },
        {
          "hoverHint": "Any other essential expenses.",
          "total": 0,
          "name": "Other",
          "items": []
        }
      ]
    },
    {
      "hoverHint": "Expenses that are intended to improve your quality of life.",
      "name": "Lifestyle Expenses",
      "description": "Does LE change?",
      "categories": [
        {
          "hoverHint": "Expenses related to your mental and physical well-being, generally, treating yourself.",
          "total": 270,
          "name": "Self Care",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Therapy",
              "value": 145
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Chiropractor",
              "value": 125
            }
          ]
        },
        {
          "hoverHint": "Expenses related to 'going out on the town' whether this means lunch, a night out, or a concert.",
          "total": 200,
          "name": "Going Out",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Dinner with Friends",
              "value": 75
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Clubbing",
              "value": 50
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Drinks with Colleagues",
              "value": 75
            }
          ]
        },
        {
          "total": 150,
          "hoverHint": "Generally, new clothes, household items, or toys.",
          "name": "Shopping",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "New Clothes",
              "value": 150
            }
          ]
        },
        {
          "total": 200,
          "hoverHint": "Expenses for traveling or taking a vacation.",
          "name": "Travel & Vacation",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Weekend Trip",
              "value": 200
            }
          ]
        },
        {
          "total": 114.97,
          "hoverHint": "Subscriptions that improve your quality of life such as streaming services.",
          "name": "Subscriptions",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Personal Finance Software",
              "value": 15.99
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Spotify",
              "value": 18.99
            },
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "TV",
              "value": 79.99
            }
          ]
        },
        {
          "hoverHint": "Any other expenses that are not essential, but are intended to improve your quality of life.",
          "total": 40,
          "name": "Other",
          "items": [
            {
              "date": {
                "_seconds": 1711252800,
                "_nanoseconds": 0
              },
              "name": "Free Climbing",
              "value": 40
            }
          ]
        }
      ]
    }
  ],
  "title": "Cash Flow from Wed Nov 01 2023 to Thu Nov 30 2023",
  "payYourselfFirstPercentage": 0.45,
  "userId": "Vn3FWWY203Vn1JyB1NpivmxfLAJ3",
  "visibleToCoach": true,
  "dateCreated": {
    "_seconds": 1711315100,
    "_nanoseconds": 224000000
  },
  "netCashFlow": 115.86000000000058,
  "totalRevenue": 10200,
  "startDate": {
    "_seconds": 1698811200,
    "_nanoseconds": 0
  }
}
