import { Injectable } from '@angular/core';
import {AbstractDatabaseService} from "./abstract-database.service";
import {StatementTemplate} from "../models/statement-template";
import {FinancialPositionTemplate} from "../../financial-positions/models/financial-position-template";
import {Timestamp} from "firebase/firestore";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {TandemUser} from "../../auth/models/tandem-user";
import {Observable, of, switchMap} from "rxjs";
import {FinancialPosition} from "../../financial-positions/models/financial-position";
import {CashFlow} from "../../cash-flows/models/cash-flow";
import {SpendingPlan} from "../../spending-plans/models/spending-plan";
import {CashFlowTemplate} from "../../cash-flows/models/cash-flow-template";

@Injectable({
  providedIn: 'root'
})
export class StatementTemplateService extends AbstractDatabaseService<StatementTemplate> {

  private DEFAULT_TEMPLATE: StatementTemplate = {
    revenueSections: [
      {
        name: 'Revenue',
        description: 'Cash coming in',
        hoverHint: 'Cash coming in.',
        categories: [
          {
            name: 'Active Income',
            items: [],
            total: 0,
            hoverHint: 'Earnings from directly trading time for money whether it\'s in a business, job, or side hustle.'
          },
          {
            name: 'Passive Income',
            items: [],
            total: 0,
            hoverHint: 'Earnings in which you do not directly trade time for money.'
          },
          {
            name: 'Unexpected Income',
            items: [],
            total: 0,
            hoverHint: 'Cash flow that you don\'t expect to have with regularity'
          },
          {
            name: 'Asset Liquidation',
            items: [],
            total: 0,
            hoverHint: 'Cash generated from the sale of an asset or liquidation of an account.'
          }
        ]
      }
    ],
    expenseSections: [
      {
        name: 'Pay Yourself First Expenses',
        description: 'Expenses that pay into the future of the most important person in your life, you',
        hoverHint: 'Expenses that pay into the future of the most important person in your life, you.',
        categories: [
          {
            name: 'Charitable Contributions',
            items: [],
            total: 0,
            hoverHint: 'Income freely given to charitable or faith-inspired causes.'
          },
          {
            name: 'Investing In Yourself',
            items: [],
            total: 0,
            hoverHint: 'Investments into your skills, knowledge, and network that will increase your future earning potential.'
          },
          {
            name: 'Working Assets',
            items: [],
            total: 0,
            hoverHint: 'The purchase of assets that will generate wealth on your behalf such as financial securities.'
          },
          {
            name: 'Debt Paydown',
            items: [],
            total: 0,
            hoverHint: 'Payments to debt above and beyond the minimum payment.'
          }
        ]
      },
      {
        name: 'Essential Expenses',
        description: 'Expenses essential to your basic survival; groceries, housing, transportation, etc',
        hoverHint: 'Expenses essential to your basic survival; groceries, housing, transportation, etc.',
        categories: [
          {
            name: 'Housing',
            items: [],
            total: 0,
            hoverHint: 'Expenses associated with having a roof over your head and a warm bed to sleep in at night.'
          },
          {
            name: 'Transportation',
            items: [],
            total: 0,
            hoverHint: 'Transportation expenses you need to pay for the essentials in life, such as driving to work.'
          },
          {
            name: 'Insurance & Utilities',
            items: [],
            total: 0,
            hoverHint: 'Insurance and utility bills paid to maintain the essentials of life, such as the electric bill.'
          },
          {
            name: 'Groceries & Toiletries',
            items: [],
            total: 0,
            hoverHint: 'Food and household items necessary to sustain yourself.'
          },
          {
            name: 'Medical',
            items: [],
            total: 0,
            hoverHint: 'Necessary medical expenses such as prescriptions, check-ups, and walk-in visits.'
          },
          {
            name: 'Childcare',
            items: [],
            total: 0,
            hoverHint: 'Expenses such as daycare and diapers that are essential to a healthy childhood.'
          },
          {
            name: 'Debt Payments',
            items: [],
            total: 0,
            hoverHint: 'The minimum payments made on existing debt.'
          },
          {
            name: 'Other',
            items: [],
            total: 0,
            hoverHint: 'Any other essential expenses.'
          }
        ]
      },
      {
        name: 'Lifestyle Expenses',
        description: 'Expenses that are intended to improve your quality of life',
        hoverHint: 'Expenses that are intended to improve your quality of life.',
        categories: [
          {
            name: 'Self Care',
            items: [],
            total: 0,
            hoverHint: 'Expenses related to your mental and physical well-being, generally, treating yourself.'
          },
          {
            name: 'Going Out',
            items: [],
            total: 0,
            hoverHint: 'Expenses related to \'going out on the town\' whether this means lunch, a night out, or a concert.'
          },
          {
            name: 'Shopping',
            items: [],
            total: 0,
            hoverHint: 'Generally, new clothes, household items, or toys.'
          },
          {
            name: 'Travel & Vacation',
            items: [],
            total: 0,
            hoverHint: 'Expenses for traveling or taking a vacation.'
          },
          {
            name: 'Subscriptions',
            items: [],
            total: 0,
            hoverHint: 'Subscriptions that improve your quality of life such as streaming services.'
          },
          {
            name: 'Other',
            items: [],
            total: 0,
            hoverHint: 'Any other expenses that are not essential, but are intended to improve your quality of life.'
          }
        ]
      }
    ],
    coachId: '',
    userId: '',
    dateCreated: Timestamp.now(),
    dateModified: Timestamp.now(),
    cashFlowName: 'Cash Flow',
    cashFlowNamePlural: 'Cash Flows',
    spendingPlanName: 'Spending Plan',
    spendingPlanNamePlural: 'Spending Plans',
    cashFlowDescription: 'Captures all of your saving and spending activity in order to determine your net cash flow over a given period of time.',
    spendingPlanDescription: 'Prioritize yourself, establish a plan, and attack your goals with a sense of direction'
  };
  constructor(protected override afs: AngularFirestore) {
    super('statementTemplates', afs);
  }

  public getTemplateForUser(user: TandemUser): Observable<StatementTemplate> {
    if (user.statementTemplateId) {
      return this.get(user.statementTemplateId);
    } else if (user.coachId) {
      return this.getTemplateByCoachId(user.coachId);
    } else {
      return this.getDefaultTemplate();
    }
  }

  public generateDefaultCashFlow(): CashFlow {
    let startDate = new Date();
    let endDate = new Date(startDate);
    const currentTimestamp = Timestamp.now();
    return {
      dateCreated: currentTimestamp,
      dateModified: currentTimestamp,
      title: `Cash Flow from ${startDate.toDateString()} to ${endDate.toDateString()}`,
      startDate: Timestamp.fromDate(startDate),
      endDate: Timestamp.fromDate(endDate),
      userId: '',
      netCashFlow: 0,
      revenueSections: this.DEFAULT_TEMPLATE.revenueSections,
      expenseSections: this.DEFAULT_TEMPLATE.expenseSections,
      totalRevenue: 0,
      totalExpenses: 0,
      visibleToCoach: true
    }
  }

  public generateDefaultSpendingPlan(): SpendingPlan {
    let startDate = new Date();
    let endDate = new Date(startDate);
    const currentTimestamp = Timestamp.now();
    return {
      dateCreated: currentTimestamp,
      dateModified: currentTimestamp,
      title: `Spending Plan from ${startDate.toDateString()} to ${endDate.toDateString()}`,
      startDate: Timestamp.fromDate(startDate),
      endDate: Timestamp.fromDate(endDate),
      userId: '',
      netCashFlow: 0,
      revenueSections: this.DEFAULT_TEMPLATE.revenueSections,
      expenseSections: this.DEFAULT_TEMPLATE.expenseSections,
      totalRevenue: 0,
      totalExpenses: 0,
      visibleToCoach: true
    }
  }

  public generateDefaultTemplate(userId: string): StatementTemplate {
    let template = this.DEFAULT_TEMPLATE;
    template.userId = userId;
    return template;
  }

  private getTemplateByCoachId(coachId: string): Observable<StatementTemplate> {
    return this.afs.collection<StatementTemplate>('statementTemplates', ref => ref.where('coachId', '==', coachId)).valueChanges().pipe(
      switchMap(templates => {
        if (templates.length > 0) {
          return of(templates[0]);
        } else {
          return this.getDefaultTemplate();
        }
      })
    );
  }

  private getDefaultTemplate(): Observable<StatementTemplate> {
    return of(this.DEFAULT_TEMPLATE);
  }
}
