import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {EChartsOption} from "echarts";
import {NgxEchartsDirective, NgxEchartsModule} from "ngx-echarts";
import {isPlatformBrowser, NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {ChartService} from "../../../../../tandem-core/services/chart.service";
import {ThemingService} from "../../../../../tandem-core/services/theming.service";


export interface DebtDataPoint {
  period: number;
  months: number;
  dateLabel: Date;
  beginningBalance: number;
  payment: number;
  interest: number;
  principal: number;
  endingBalance: number;
}

@Component({
  selector: 'app-debt-payoff',
  standalone: true,
  imports: [
    NgxEchartsModule,
    NgIf,
    RouterLink
  ],
  providers: [
  ],
  templateUrl: './debt-payoff.component.html',
  styleUrls: ['./debt-payoff.component.scss']
})
export class DebtPayoffComponent implements OnInit {

  @Input() useCase: string = 'general';

  chartOptions?: EChartsOption;

  dataPoints: DebtDataPoint[] = [];
  lastDataPoint: DebtDataPoint | null = null;
  minimumDataPoints: DebtDataPoint[] = [];
  lastMinimumDataPoint: DebtDataPoint | null = null;
  monthLabels: string[] = [];
  frequencyConversionMap: Map<number, number> = new Map([
    [ 52, 12 / 52 ],  // Weekly
    [ 26, 12 / 26 ],  // Biweekly
    [ 12, 1 ],        // Monthly
    [ 4, 3 ],         // Quarterly
    [ 2, 6 ],         // Semiannually
    [ 1, 12 ]         // Annually
  ]);
  private currentTheme: string = '#0684ff';

  constructor(private chartService: ChartService, @Inject(PLATFORM_ID) private platformId: Object, private themeService: ThemingService) {
  }

  ngOnInit(): void {
    this.themeService.selectedTheme.subscribe(theme => {
      this.currentTheme = theme;
      this.renderLineChart();
    });
    this.renderLineChart();
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public renderLineChart() {

    // this.calculateDataPoints('payment');
    // this.calculateDataPoints('minimumPayment');

    this.calculateMinimumDataPoints();
    this.calculateUserDataPoints();

    // Adjust for difference in number of data points
    if (this.dataPoints.length < this.minimumDataPoints.length) {
      while (this.dataPoints.length < this.minimumDataPoints.length) {
        const paymentFrequency: number = 12;
        let months = (this.lastDataPoint!.months + 1) + this.frequencyConversionMap.get(paymentFrequency)!;
        const labelDate = new Date();
        labelDate.setMonth(labelDate.getMonth() + months);
        const dataPoint: DebtDataPoint = {
          period: this.dataPoints.length,
          months: months,
          dateLabel: labelDate,
          beginningBalance: 0,
          interest: 0,
          principal: 0,
          endingBalance: 0,
          payment: 0
        }
        this.lastDataPoint = dataPoint;
        this.dataPoints.push(dataPoint);
      }
    }

    this.chartOptions = this.chartService.generateLineChart('Date', 'Value', this.monthLabels, [
      {name: 'User Payment Strategy', data: this.dataPoints.map(t => t.endingBalance), color: this.currentTheme},
      {name: 'Minimum Payment Strategy', data: this.minimumDataPoints.map(t => t.endingBalance), color: `#000`},
    ]);
  }

  calculateMinimumDataPoints() {
    const beginningBalance: number = 40000;
    const annualInterestRate: number = 0.045;
    const initialContribution: number = 0;
    let payment: number = 450;
    const paymentFrequency = 12;


    const currentDate = new Date();

    this.minimumDataPoints = [];
    this.monthLabels = [`${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`]

    const firstPoint: DebtDataPoint = {period: 0, months: 0, dateLabel: new Date(), beginningBalance: beginningBalance, payment: initialContribution, interest: 0, principal: 0, endingBalance: beginningBalance - initialContribution};
    this.minimumDataPoints.push(firstPoint);
    this.lastMinimumDataPoint = firstPoint;

    const maxIterations = 1000;  // for example
    let iterations = 0;

    while (this.lastMinimumDataPoint!.endingBalance > 0 && iterations < maxIterations) {

      let interest: number = this.lastMinimumDataPoint.endingBalance * (annualInterestRate / paymentFrequency);
      let principal: number = payment - interest;
      let months = this.lastMinimumDataPoint.months + this.frequencyConversionMap.get(paymentFrequency)!;

      const labelDate = new Date();
      labelDate.setMonth(labelDate.getMonth() + months);
      this.monthLabels.push(`${labelDate.getMonth() + 1}/${labelDate.getFullYear()}`);

      const dataPoint:DebtDataPoint = {
        period: ++this.lastMinimumDataPoint.period,
        months: months,
        dateLabel: labelDate,
        beginningBalance: this.lastMinimumDataPoint.endingBalance,
        payment: payment,
        interest: interest,
        principal: principal,
        endingBalance: this.lastMinimumDataPoint.endingBalance - principal
      };

      if (dataPoint.endingBalance < 0) {
        dataPoint.endingBalance = 0;
      }

      this.minimumDataPoints.push(dataPoint);
      this.lastMinimumDataPoint = dataPoint;

      if (iterations === maxIterations) {
        console.warn("Maximum iterations reached. Please review your logic.");
      }
    }
  }

  calculateUserDataPoints() {
    const beginningBalance: number = 40000;
    const annualInterestRate: number = 0.045;
    const initialContribution: number = 0;
    let payment: number = 750;
    let paymentFrequency = 12

    payment = payment / Number(this.frequencyConversionMap.get(paymentFrequency));
    paymentFrequency = 12;


    const currentDate = new Date();

    this.dataPoints = [];

    const firstPoint: DebtDataPoint = {period: 0, months: 0, dateLabel: new Date(), beginningBalance: beginningBalance, payment: initialContribution, interest: 0, principal: 0, endingBalance: beginningBalance - initialContribution};
    this.dataPoints.push(firstPoint);
    this.lastDataPoint = firstPoint;

    const maxIterations = 1000;  // for example
    let iterations = 0;

    while (this.lastDataPoint!.endingBalance > 0 && iterations < maxIterations) {

      let interest: number = this.lastDataPoint!.endingBalance * (annualInterestRate / paymentFrequency);
      let principal: number = payment - interest;
      let months = this.lastDataPoint!.months + this.frequencyConversionMap.get(paymentFrequency)!;

      const labelDate = new Date();
      labelDate.setMonth(labelDate.getMonth() + months);
      // this.monthLabels.push(`${labelDate.getMonth() + 1}/${labelDate.getFullYear()}`);

      const dataPoint:DebtDataPoint = {
        period: ++this.lastDataPoint!.period,
        months: months,
        dateLabel: labelDate,
        beginningBalance: this.lastDataPoint!.endingBalance,
        payment: payment,
        interest: interest,
        principal: principal,
        endingBalance: this.lastDataPoint!.endingBalance - principal
      };

      if (dataPoint.endingBalance < 0) {
        dataPoint.endingBalance = 0;
      }

      this.dataPoints.push(dataPoint);
      this.lastDataPoint = dataPoint;

      if (iterations === maxIterations) {
        console.warn("Maximum iterations reached. Please review your logic.");
      }
    }
  }
}
